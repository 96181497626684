<template>
    <div>
        <div class="bottom_box">
            <div class="nei_bottom_box">
                <div class="one_box">
                    <div class="text_1">{{info.web_site_title}}</div>
                    <div style="margin-top:10px" class="text_2"><div class="title">姓名：</div> <div class="content">{{info.web_site_name}}</div></div>
                    <div class="text_2"><div class="title">电话：</div> <div class="content">{{info.web_site_tel}}</div></div>
                    <div class="text_2"><div class="title">邮箱：</div> <div class="content">{{info.web_site_phone}}</div></div>
                    <div class="text_2"><div class="title">地址：</div> <div class="content">{{info.web_site_address}}</div></div>
                </div>
                <!-- <div class="two_box">
                    <div class="code_box"><img :src="info.web_official_code" alt=""></div>
                    <div class="code_box"><img :src="info.code2" alt=""></div>
                </div> -->
                <div class="three_box">
                    <div class="top_txt">咨询</div>
                    <div class="mb-5"><textarea v-model="leavingData.message" class="textarea_style" placeholder="您想咨询什么？"></textarea></div>
                    <div class="input_box">
                        <div style="width:46%">
                            <div class="top_txt">姓名</div>
                            <div><input v-model="leavingData.name" class="input_style" type="text" placeholder="请输入您的姓名"></div>
                        </div>
                        <div style="width:46%">
                            <div class="top_txt">邮箱</div>
                            <div><input v-model="leavingData.phone" class="input_style" type="text" placeholder="请输入您的邮箱"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="Bottom_btn">
                <div @click="SubmitBtn" class="infoBtn">提交信息</div>
            </div>
          <div class="Record_number">{{info.web_keep}}</div>
          
        </div>
    </div>
</template>

<script>
import {configshow,indexonline} from '@/api/index.js'
export default {
    name: 'HelloWorld',
    data() {
        return {
            leavingData:{
                name:'',
                phone:'',
                message:''
            }
        }
    },
    props:{
      info:{
        type:Object
      }
    },
    created() {
    },
    methods: {
        // configshow(){
        //     configshow().then(res=>{
        //         this.info = res.data
        //     })
        // },
        SubmitBtn(){
            indexonline(this.leavingData).then(res=>{
                this.$Message.success(res.message)
                this.leavingData = {
                    name:'',
                    phone:'',
                    message:''
                }
            }).catch(err=>{
                this.$Message.error(err.message)
            })
        },
    },
}
</script>

<style scoped lang="scss">
.bottom_box{
    width: 100%;
    // height: 380px;
    background: #313131;
    padding-top: 60px;
    padding-bottom: 20px;
}
.nei_bottom_box{
    width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.one_box{
    color: #fff;
    width: 500px;
}
.Bottom_btn{
    width: 80%;
    margin: 0 auto;
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
}
.infoBtn{
    cursor: pointer;
    width: 400px;
    text-align: center;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 10px 0;
}
.two_box{
    width: 500px;
    display: flex;
    // align-items: center;
    justify-content: space-between;
}
.code_box{
    width: 190px;
    height: 190px;
    border-radius: 5px;
    background: #FFF;
    padding: 10px;
}
.code_box img{
    width: 100%;
    height: 100%;
}
.three_box{
    width: 400px;
}
.text_1{
    font-size: 20px;
}
.text_2{
    padding: 5px 0 10px;
    font-size: 13px;
    display: flex;
}
.title{
    // width: 25%;
}
.content{
    // width: 75%;
}
.top_txt{
    font-size: 16px;
    color: #FFF;
    margin-bottom: 5px;
}
.Record_number{
    text-align: center;
    color: #FFF;
    margin-top: 60px;
    font-size: 12px;
}
.textarea_style{
    outline: none;
    background: none;
    border: 1px solid #FFF;
    color: #FFFFFF;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
    box-sizing: border-box;
    min-width: 100%;
    max-width: 100%;
    min-height: 80px;
    max-height: 80px;
}
.input_style{
    outline: none;
    background: none;
    border: 1px solid #FFF;
    color: #FFFFFF;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 5px;
    width: 100%;
}
input::-webkit-input-placeholder{
    color:#eee;
    font-size:12px;
}
textarea::-webkit-input-placeholder{
    color:#eee;
    font-size:12px;
}
.mb-5{
    margin-bottom: 5px;
}
.input_box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.last_box{
  width: 100%;
  margin-top:10px;
  color:#fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
.bei_text{
  color:#fff;
}
.jin_bei_text{
  display: flex;
  align-items: center;
  color: #fff;
  margin-left: 50px
}
</style>
