<template>
  <div>
      <div class="hearder_box">
          <div class="nav_box">
              <div id="iconClass" @mouseover="mouseOver" @mouseleave="mouseLeave" @click="homeBtn" v-if="info.web_site_logo" class="logo_box"><img :src="info.web_site_logo"></div>
              <div class="menu_box">
                  <div @click="menuClick(item)" :class="checkValue==item.id?'check_yes item_menu':'check_no item_menu'" v-for="(item,index) of menu" :key="index">
                      <div :class="checkValue==item.id?'menu_txt':'no_menu_txt'">{{item.cate_title}}</div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import { cate_list,configshow } from '@/api/index.js'
export default {
  name: 'HelloWorld',
  props:{
    checkValue:Number,
    info:Object
  },
  data() {
      return {
          menu:[]
      }
  },
  created() {
    this.cate_list()
    // this.configshow()
  },
  methods: {
    mouseOver(){
      // document.getElementById('iconClass').className="logo_box animate__animated animate__swing"
      // console.log('移入');
    },
    mouseLeave(){
      // console.log('移出');
    },
      cate_list(){
        cate_list().then(res=>{
          this.menu = res.data
        })
      },
      // configshow(){
      //       configshow().then(res=>{
      //           this.info = res.data
      //       })
      //   },
      menuClick(e){
          this.$router.push(e.route);
      },
      homeBtn(){
        this.$router.push('/');
      }
  },
}
</script>

<style scoped lang="scss">
    .hearder_box{
      width: 100%;
      height: 50px;
      background:  #fff;
      opacity: .5;
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      top:0;
      z-index:999
    }
    .hearder_box:hover{
        opacity: 1;
        animation: zoom 1s forwards alternate;
    }
    .hearder_box:hover .logo_box, 
    .hearder_box:hover .item_menu{
        animation: zoom 1s forwards alternate;
    }
    @keyframes zoom {
      0% {
        height: 50px;
      }
      100% {
        height: 80px;
      }
    }
    .nav_box{
      width: 80%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    
    .hearder_box:hover .logo_box{
        animation: logo_zoom 1s forwards alternate;
    }
    
    @keyframes logo_zoom {
      0% {
        width: 240px;
        height: 50px;
      }
      100% {
        width: 384px;
        height: 80px;
      }
    }
    .logo_box{
      object-fit: cover;
      cursor: pointer;
      width: 240px;
      height: 50px;
    }
    .logo_box img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .menu_box{
      display: flex;
      align-items: center;
      font-size: 16px;
    }
    .item_menu{
      width: 140px;
      height: 50px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .check_yes{
      cursor: pointer;
      background: #313131;
      color: #fff;
    }
    .check_no{
      cursor: pointer;
      color: #313131;
    }
    .check_no:hover{
      background: #313131;
      color: #fff;
    }
    .menu_txt{
      margin-top: 11px;
      padding-bottom: 10px;
      border-bottom: 1px solid #fff;
    }
</style>
