<template>
    <div class="box">
        <div class="frame_box">
            <div @click="closeModal" class="close_icon"><img src="../assets/images/close.png" alt=""></div>
            <div class="title_name">合作咨询</div>
            <!-- <div class="peoplenum">已有<span class="people_color">566</span>人加盟</div> -->
            <div class="info_box">
                <div class="info_left">
                    <div>姓名 <span class="people_color"> * </span></div>
                    <div><input v-model="leavingData.name" class="phone_input" placeholder="请输入姓名" type="text"></div>
                </div>
                <div class="info_right">
                    <div>邮箱 <span class="people_color"> * </span></div>
                    <div><input v-model="leavingData.phone" class="phone_input" placeholder="请输入邮箱" type="text"></div>
                </div>
                <!-- <div class="info_right">
                    <div>短信验证 <span class="people_color"> * </span></div>
                    <div class="yzm_box">
                        <input class="yzm_Input" placeholder="请输入验证码" type="text">
                        <div @click="getCode" class="yzm_txt">{{show?'获取验证码':`${count}秒`}}</div>
                    </div>
                </div> -->
            </div>
            <div class="info_box_zixun">
                <div class="info_left_zixun">
                    <div>咨询 <span class="people_color"> * </span></div>
                    <div>
                        <textarea v-model="leavingData.message" placeholder="您想咨询什么" class="textarea_style"></textarea>
                    </div>
                </div>
            </div>
            <div @click="addBtn" class="submit_btn">提交信息</div>
            <!-- <div class="radio_box">
                <div class="rido_box">
                    <img src="../assets/images/rido.png" alt="">
                    <div>季先生</div>
                </div>
                <div>150***36562</div>
                <div class="receive_txt">领取成功</div>
            </div> -->
        </div>
    </div>
</template>

<script>
    import {indexonline} from '@/api/index.js'
    export default {
        data(){
            return{
                show: true,
                count: '',
                timer: null,
                leavingData:{
                    name:'',
                    phone:'',
                    message:''
                }
            }
        },
        methods:{
            getCode(){
                const TIME_COUNT = 59;
                if (!this.timer) {
                    this.count = TIME_COUNT;
                    this.show = false;
                    this.timer = setInterval(() => {
                        if (this.count > 0 && this.count <= TIME_COUNT) {
                            this.count--;
                        } else {
                            this.show = true;
                            clearInterval(this.timer);
                            this.timer = null;
                        }
                    }, 1000)
                }else{
                    this.$Message.success('验证码已发送')
                }
            },
            addBtn(){
                indexonline(this.leavingData).then(res=>{
                    localStorage.setItem('message', 1)
                    this.$emit('closepop',false)
                    this.$Message.success(res.message)
                    this.leavingData = {
                        name:'',
                        phone:'',
                        message:''
                    }
                }).catch(err=>{
                    this.$Message.error(err.message)
                })
            },
            closeModal(){
                this.leavingData = {
                    name:'',
                    phone:'',
                    message:''
                }
                this.$emit('closepop',false)
            },
        }
    }
</script>

<style scoped>
.box{
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,.5);
    display: flex;
    align-items: center;
    justify-content: center;
}
.frame_box{
    position: relative;
    width: 700px;
    background: #FFF;
    border-radius: 10px;
}
.close_icon{
    position: absolute;
    top: 15px;
    right: 20px;
    /* width: 20px;
    height: 20px; */
    width: 5px;
    height: 5px;
    cursor: pointer;
}
.close_icon img{
    width: 100%;
    height: 100%;
}
.title_name{
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #000;
    padding: 10px 0;
}
.peoplenum{
    text-align: center;
    padding: 5px 0;
    color: #999;
}
.people_color{
    color: red;
}
.info_box{
    width: 100%;
    display: flex;
    /* padding: 0 10px; */
    align-items: center;
    justify-content: space-around;
    margin-top: 50px;
}
.info_box_zixun{
    width: 100%;
    display: flex;
    padding: 0 35px;
    align-items: center;
    /* justify-content: space-around; */
    margin-top: 20px;
}
.info_left{
    width: 40%;
}
.info_left_zixun{
    width: 100%;
}
.phone_input{
    outline: none;
    border: none;
    background: #F7F7F7;
    line-height: 40px;
    padding: 0 10px;
    width: 100%;
    margin-top: 10px;
}
.info_right{
    width: 40%;
}
.yzm_box{
    width: 100%;
    height: 40px;
    background: #F7F7F7;
    margin-top: 10px;
    padding: 0 10px;
    display: flex;
    align-items: center;
}
.yzm_Input{
    width: 60%;
    outline: none;
    border: none;
    background: #F7F7F7;
    padding-right: 20px;
}
.yzm_txt{
    width: 40%;
    text-align: center;
    border-left: 1px solid #999;
    cursor: pointer;
    color: #999;
}
.submit_btn{
    background: #A91800;
    color: #FFF;
    width: 260px;
    text-align: center;
    padding: 15px 0;
    margin: 50px auto 30px;
    border-radius: 5px;
    /* margin-top: 80px; */
    cursor: pointer;
}
.radio_box{
    background: #F8EDEB;
    width: 260px;
    margin: 10px auto 20px;
    border-radius: 20px;
    padding: 5px 10px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.rido_box{
    display: flex;
    align-items: center;
}
.rido_box img{
    width: 16px;
    height: 16px;
    margin-right: 10px;
}
.receive_txt{
    color: #A91800;
    cursor: pointer;
}
.textarea_style{
    outline: none;
    border: none;
    background: #F7F7F7;
    max-height: 100px;
    min-height: 100px;
    width: 100%;
    padding: 10px;
}
</style>